import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import './pragencyflorida.scss';

class CSPForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/pr-agency-florida/";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                // window.open(
                //     'https://mm-stats.com/bu0py',
                //     '_blank' // <- This is what makes it open in a new tab.
                // );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"namehero"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"emailhero"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companyhero"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephonehero"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">SUBMIT</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/pr-agency-florida/" />
                    <input type="hidden" name="auth" value="a00c15ac-260e-4ade-ad04-5ded2ef1ce71" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} praf-template`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <h1>Merit Mile - Florida<br />PR Agency<span className="bar"></span></h1>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>Let's talk about your brand and vision today!</h2>
                                <div className="form-wrapper">
                                    <CSPForm />
                                    <div className="thankyou">
                                    <h4>Thank You</h4>
                                    <p>Your submission has been received.</p>
                                    <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2>Our PR Philosphy</h2>
                            <p>At Merit Mile, a PR agency based in Florida, we specialize in delivering results-driven strategic communications and public relations solutions that amplify business impact across several critical industries. Located in Boca Raton, Florida, our firm is committed to helping companies build strong, authentic connections with their audiences through tailored messaging and comprehensive communications strategies. With expertise spanning B2B storytelling, consumer PR campaigns, and media relations, we create powerful narratives that resonate across industries and deliver publicity, thought leadership, and uniquely differentiated brand awareness. Furthermore, our public relations services are  complemented by the broader, full-service capabilities of Merit Mile, including creative services, interactive development, content marketing, and video production. </p>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/our-pr-philosophy.webp" alt="Our PR Philosophy" className="img-responsive oprf" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Our PR approach is rooted in the belief that great publicity goes beyond traditional outreach. We integrate branding, marketing, advertising, and interactive elements into our campaigns, ensuring our clients' messages not only reach the right people but leave a lasting impression. Whether it’s launching a product, enhancing brand reputation, or positioning thought leaders in their field, we align with our clients’ goals to create meaningful, measurable results utilizing the latest resources, tools, and technologies. </p>
                            <p>In addition to our PR services, we offer a robust industry market research division that helps clients take the pulse of their markets and stakeholder perceptions. By understanding the key concerns, opportunities, and beliefs within an industry, we deliver data-driven insights that shape PR strategies and business narratives. This allows our clients to position themselves as leaders in their categories.</p>
                            <p>Here at Merit Mile, PR Agency Florida, our philosophy is rooted in the power of storytelling to influence decision-makers. With a client-focused, results-driven approach, we help brands thrive in today’s competitive landscape. From crafting strategic messaging to executing integrated campaigns, we are your trusted partner for communication success. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/our-pr-focus.webp" alt="Our PR Focus" className="img-responsive oprf" />
                        </div>
                        <div className="col-12 col-md-7">
                            <h2>Our PR Focus</h2>
                            <p>At Merit Mile, PR Agency Florida, we bring deep industry knowledge and a strategic focus to both B2B and consumer campaigns. Our firm has extensive experience in B2B sectors, including automotive, healthcare, financial services, insurance, technology, supply chain/logistics, and transportation. We understand the unique challenges and opportunities within these industries and work closely with clients to craft tailored PR strategies that build trust, drive engagement, and position clients as leaders in their respective markets. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>In the B2B space, we excel at creating strategic communications and thought leadership campaigns that resonate with key decision-makers. Whether it's navigating complex industry landscapes, launching new initiatives, or managing reputation, we provide the expertise and insights needed to make a lasting impact.</p>
                            <p>For consumer brands, our focus is on shaping individual perceptions through dynamic and engaging campaigns. We help brands tell their story in ways that connect emotionally with their target audience, building lasting relationships and driving brand loyalty. From product launches to reputation management, our consumer PR strategies are designed to meet the evolving expectations of today’s savvy consumers.</p>
                            <p>To complement our PR efforts, we also create compelling content marketing that supports your brand's message across multiple platforms. Our team develops social media content, short-form videos, white papers, and infographics that provide valuable insights and drive engagement. Whether you're looking to enhance your thought leadership, educate your audience, or promote your products, our content marketing solutions are designed to deliver measurable results. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <p style={{ marginTop: '2em' }}><b>At Merit Mile, PR Agency Florida, we combine our industry expertise with a results-driven approach to communications, ensuring that your brand’s story is not only heard but remembered. Industries in focus:</b></p>
                            <ul>
                                <li>Consumer</li>
                                <li>B2B</li>
                                <li>Content marketing<br />(Video, White papers, infographics, and press kits)</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-7">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/pr-industry-expertise.webp" alt="PR Industry Expertise" className="img-responsive industryexpertise" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="videowrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 order-md-1 col-lg-6">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/pr-thought-leadership.webp" alt="Microsoft CSP" className="img-responsive" />
                        </div>
                        <div className="col-12 col-md-8 order-md-12 col-lg-6">
                            <h2>PR Agency Florida<br />Thought Leadership</h2>
                            <p>Thought leadership campaigns are vital for brands seeking to stand out and lead in their industry. At Merit Mile, PR Agency Florida, we understand that in today’s fast-paced, competitive market, brands need to do more than just offer products or services—they need to shape conversations, influence decision-makers, and drive industry trends. Thought leadership allows brands to showcase their expertise, build credibility, and foster trust with their audience.</p>
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <p>We leverage our in-depth knowledge of various industries—such as automotive, healthcare, financial services, and technology—to craft strategic messaging that positions our clients in a unique, differentiated light. By staying informed on the events, issues, and innovations shaping each sector, we help clients address the challenges their audience faces and provide forward-thinking solutions that set them apart from competitors. </p>
                            <p>Our approach to thought leadership includes producing bylined articles, white papers, keynote speeches, and expert commentary that highlight our clients’ insights and vision. This not only enhances brand reputation but also helps clients establish themselves as category leaders, influencing the future of their industry. At Merit Mile, PR Agency Florida, our goal is to ensure your brand is recognized as a trusted authority and visionary leader. </p>
                            <p>This approach is important because many brands today face challenges in identifying opportunities to generate their own thought leadership due to the complexity of establishing authority and trust within their industries. Thought leadership involves creating insightful, original content that positions a brand as an industry expert, which can be time-consuming and requires a strategic approach. Brands need to differentiate themselves by consistently delivering valuable information, engaging with audiences through various channels like blogs and social media, and leveraging unique insights. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Types of Media We Work With</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Consumer and B2B</li>
                                <li>Digital/Online</li>
                                <li>Print/Offline</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Broadcast, TV, Radio, Podcast</li>
                                <li>Domestic/International</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro" style={{ marginTop: '4em '}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Trade Shows and Event PR</h2>
                            <p>At Merit Mile, PR Agency Florida, we specialize in planning and executing successful media tours that provide clients with valuable one-on-one interactions with targeted media. Media tours are a powerful way to build relationships with key journalists and influencers, allowing clients to directly convey their messaging and insights in a more personalized setting. These tours are especially effective for reaching specific regions or markets, helping clients generate localized media coverage and gain visibility in important geographic areas.</p>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>NADA</li>
                                <li>Microsoft Inspire</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Consumer Electronic Show (CES)</li>
                                <li>HIMMS and many more!</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>PR Media Tours</h2>
                            <p>At Merit Mile, PR Agency Florida, we specialize in planning and executing successful media tours that provide clients with valuable one-on-one interactions with targeted media. Media tours are a powerful way to build relationships with key journalists and influencers, allowing clients to directly convey their messaging and insights in a more personalized setting. These tours are especially effective for reaching specific regions or markets, helping clients generate localized media coverage and gain visibility in important geographic areas.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/media-tours.webp" alt="PR Media Tours" className="img-responsive mediatours" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>We coordinate every aspect of media tours, from deskside briefings with top-tier journalists to multi-city tours, ensuring that clients meet with the right media outlets to tell their story. Whether it’s securing in-studio interviews with local broadcast media or arranging in-person meetings with national business press in major hubs like New York City and Chicago, we ensure that each interaction is impactful. Our team also handles on-site video production with national media, giving clients the opportunity to create compelling visual content that enhances their media presence.</p>
                            <p>By connecting directly with influential media through face-to-face meetings, media tours offer clients the chance to strengthen their media relationships, position themselves as thought leaders, and secure impactful coverage. Merit Mile, PR Agency Florida ensures these tours are seamless, strategically planned, and aligned with your brand's goals, driving results that build long-term media relationships and visibility. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Experience taking your story to the media</li>
                                <li>Deskside briefings</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Video interviews</li>
                                <li>Video demonstrations (PYB)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/pr-measurement-obsessed.webp" alt="PR Measurement Obsessed" className="img-responsive obsessed" />
                        </div>
                        <div className="col-12 col-md-6">
                            <h2>We are PR Measurement Obsessed</h2>
                            <p>Introducing Merit Mile Metrix, PR Agency Florida's proprietary PR and campaign measurement tool designed to provide unparalleled insights into the effectiveness of your campaigns. Metrix eliminates the complexities of traditional project management and CRM tools by offering a streamlined, tailored solution for agencies and services companies. Unlike many “one size fits all” platforms with escalating fees for basic features, Metrix is specifically designed to meet the unique needs of PR professionals and services organizations.</p>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <p>Built as a cloud-based, multi-tenant, role-based web application, Metrix allows you to manage every aspect of your PR and marketing campaigns with ease. From project and task management to real-time profitability tracking and Gantt chart visibility, Metrix offers deep insights into campaign performance. The tool also includes automated ROI measurement for advertising campaigns, client status reports, and detailed analytics through customizable charts. </p>
                            <p>Metrix integrates seamlessly with your existing systems, offering Salesforce and Office 365 integration, as well as website CMS and form submission management. It supports secure document storage, staff productivity management, expense reporting, and even includes an online surveys platform with automated data analysis, making it a comprehensive solution for PR agencies and their clients.</p>
                            <p>Whether you're running a multi-city media tour, managing a thought leadership campaign, or measuring the impact of a new product launch, Metrix gives you the tools you need to drive results, measure success, and deliver ROI for your clients—without the hassle or hidden costs of traditional platforms. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Proprietary (SaaS) web application </li>
                                <li>Multi-tenant, role/permissions-based </li>
                                <li>Multi-factor authentication</li>
                                <li>White labeled for you and your clients </li>
                                <li>Fully responsive for desktops, tablets and phones </li>
                                <li>Project and automated task management </li>
                                <li>Project estimating and real-time profitability measurement </li>
                                <li>Deep project status, Gantt charts & milestone visibility</li>
                                <li>Staff time and productivity management</li>
                                <li>Advertising campaign execution and ROI measurement</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6">
                            <ul>
                                <li>Lead and contact management with forecasting models</li>
                                <li>Client status reports with styled charts </li>
                                <li>Expense management, approval and reporting </li>
                                <li>Online surveys platform with automated data analysis and BI</li>
                                <li>Cloud-based document repository and short-linking </li>
                                <li>Website CMS and landing page integration</li>
                                <li>Online form submission management and back-ups</li>
                                <li>Salesforce.com / Pardot form handling integration (if necessary) </li>
                                <li>Office 365 integration</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro" style={{ marginTop: '4em '}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2>Integration With Advertising and<br />Creative Services Marketing</h2>
                            <p>Merit Mile, PR Agency Florida stands apart from the competition by offering a truly integrated approach to public relations. Our distinct advantage lies in the daily collaboration between our PR team and our dedicated advertising and marketing specialists. This seamless integration ensures that each PR campaign is not only effective on its own but also aligns perfectly with broader branding, advertising, marketing, and interactive strategies for select clients. </p>
                            <p>By blending strategic PR with advertising and marketing, we help brands craft a cohesive, compelling story that resonates across all channels. Whether it’s a press release, thought leadership article, social media campaign, or video content, every piece of communication is aligned with the overall brand strategy. This results in a unified message that engages key audiences more effectively and drives measurable business impact. </p>
                            <p>Our integrated approach amplifies PR efforts, giving brands increased visibility across both earned and paid media. For example, a PR-driven media tour or press release may be supplemented by targeted advertising and digital marketing efforts, ensuring maximum exposure to the right audience. By coordinating these efforts, we create a consistent brand narrative that builds trust, drives awareness, and enhances reputation. </p>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src="https://apiold.meritmile.com/wp-content/uploads/2025/01/integration-with-advertising.webp" alt="Integration with Advertising and Creative Services Marketing" className="img-responsive integration" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>This synergy between PR and marketing also allows us to be more agile and responsive to real-time opportunities. When a new trend or media opportunity arises, our teams work together to develop timely, multi-channel campaigns that keep our clients at the forefront of industry conversations.</p>
                            <p>Let's face it, in today's competitive marketplace, the distinction between public relations and advertising continues to blur, creating an essential synergy that not only amplifies brand visibility but also drives demand generation. When integrated effectively, these two marketing communication tactics provide remarkable benefits that enhance both awareness and consumer engagement. </p>
                            <p>At Merit Mile, PR Agency Florida, we believe the primary advantage of integrating PR and advertising lies in their collective ability to elevate marketing activities. While PR focuses on establishing credible narratives and fostering strong relationships with the public, advertising provides the strategic reminders and calls to action that drive performance. Together, they create a powerful feedback loop that not only raises awareness but also translates it into tangible demand for products or services. </p>
                            <p>Moreover, research indicates that audiences historically regard editorial content born from PR efforts as more credible than direct promotional messaging. This preference for authentic endorsements and third-party validation means that well-crafted PR campaigns can significantly enhance trust among consumers. Families are more likely to respond positively to a story featured in a trusted publication than to a traditional ad. Therefore, a strategic blend of earned media through PR can help to build this crucial credibility, enabling advertising efforts to reinforce and capitalize on the trust established. </p>
                            <p>When advertising is executed thoughtfully—timely placed and creatively produced—it acts as an effective reminder for audiences to take action. This is especially true when consumers are already familiar with a brand through PR engagements, creating a cohesive narrative that culminates in decisive consumer behavior. When a brand's messaging reverberates across multiple channels, it keeps the brand top-of-mind in a crowded landscape, increasing the likelihood of conversion. </p>
                            <p>Furthermore, leveraging the economies of scale provided by an integrated approach leads to a more efficient and economical investment. Collaborative efforts between PR and advertising agencies can streamline processes, reduce redundant strategies, and create a dulcet tone in brand messaging. This ensures that marketing dollars are used more wisely, maximizing impact without inflating costs. </p>
                            <p>Lastly, the unified strategy of blending public relations with advertising fosters a more coherent branding and promotional message. This synthesis not only creates a seamless consumer experience but also amplifies return on investment (ROI). When customers perceive consistent messages across platforms, they are more likely to engage with, remember, and advocate for the brand. </p>
                            <p>In conclusion, the integration of public relations and advertising not only capitalizes on their distinct strengths but also results in a holistic brand communication footprint. As the landscape of marketing evolves, the power of integrated marketing communications must be harnessed to foster growth, build reputation, and maximize returns in an increasingly discerning marketplace. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="intro" style={{ marginTop: '4em '}}>
                <div className="container">
                    <div className="row margin-b">
                        <div className="col-12">
                            <h2>Why Work With Us?</h2>
                            <p>At Merit Mile, PR Agency Florida, we don't just tell your story—we amplify it across every platform, ensuring your message reaches the right people in the most effective way possible. This integrated, multi-disciplinary approach results in stronger brand presence, maximized visibility, and greater business success.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div className='csrow'>
                                <a href="/case-studies/fleet-advantage/" className='fleet'>
                                    <span className='linkTitle'>Fleet<br />Advantage</span>
                                </a>
                                <a href="/case-studies/kddiamerica/" className='kddi'>
                                    <span className='linkTitle'>KDDI<br />America</span>
                                </a>
                                <a href="/case-studies/audl-pr/" className='audl'>
                                    <span className='linkTitle'>AUDL</span>
                                </a>
                                <a href="/case-studies/citrix-google-earned-media/" className='citrix'>
                                    <span className='linkTitle'>Citrix and<br />Google</span>
                                </a>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            <div className="outro">
                <a href="/services/advertising/" className='advertising'>
                    <span className='linkTitle'>Advertising</span>
                </a>
                <a href="/services/interactive/" className='interactive'>
                    <span className='linkTitle'>Interactive</span>
                </a>
                <a href="/services/public-relations/" className='pr'>
                    <span className='linkTitle'>Public Relations</span>
                </a>
                <a href="/services/social-media/" className='social-media'>
                    <span className='linkTitle'>Social Media</span>
                </a>
                <a href="/services/research/" className='research'>
                    <span className='linkTitle'>Research</span>
                </a>
            </div>
        </div>
    </LayoutLPOne>
)